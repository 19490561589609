<template>
    <v-toolbar dense dark color="secondary" id="va-toolbar" class="hidden-print-only">
      <v-app-bar-nav-icon @click="togleDrawer()"></v-app-bar-nav-icon>
      <span class="sm-font">{{$route.name}} </span>
      <v-spacer></v-spacer>
      <!-- <v-btn  v-if="$store.state.watchdraw" color="success" small @click="setOpenMachine(true)"> <v-icon color="error" left>mdi-play-circle</v-icon> watch Draw</v-btn>
       <v-spacer></v-spacer> -->
       <v-icon small left>mdi-database</v-icon>
        <strong style="color: #EE7D38;">{{$money(balance)}}</strong>
    
      <v-dialog
      v-model="confirm"
      persistent
      max-width="290">
      <v-card color="#232323" dark>
        <v-card-text class="pt-5">Continue log out?</v-card-text>
        <v-card-actions>
         
          <v-btn
            color="warning darken-1"
            text
            @click="confirm = false">Cancel</v-btn>
             <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="confirm = false, logout()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-toolbar>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
    confirm: false,
    title: null
  }),

  computed:{
    balance(){
        return  this.$store.state.balance.balance
      },
    currentRouteName() {
        return this.$route.name;
    },
    user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
  },
  methods: {
    ...mapMutations(['setDrawer',"setOpenMachine"]),
    togleDrawer () {
      this.setDrawer(true)
    },
    logout() {
      this.setDrawer(false)
      this.CLEAR_SESSION()
      this.$router.push('/login')
    }
  }
}
</script>
 