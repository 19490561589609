<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
       <v-toolbar dense dark color="secondary">
           Preview
       </v-toolbar>
        <v-card-text class="pa-4">
            <!-- BETTOR: {{$store.state.bettor_name}} -->
              GAME: {{GAMENAME(ticket.game)}}
            <br/>DRAW: {{ticket.draw_date}}
            <br/>
            <table class="va-table print_table3 mt-2  text-left" cellspacing="0">
                      <tr v-for="(item, i) in lines" :key="i">
                        <template v-if="ticket.game == '2D' || ticket.game == 'STL-SILAY' || ticket.game == '2D-STL-ILOILO-CITY' || ticket.game == 'STL-ILOILO-PROVINCE' || ticket.game == 'PERYA-ANTIQUE'">
                          <td> {{item.num1}} - {{item.num2}}

                             <!-- <template v-if="item.soldnum.length > 0">
                              <br />
                              <i class="text-warning">Sold Out: 
                                <span v-for="(sold, c) in item.soldnum" :key="c" >
                                  {{sold}}
                                </span>,
                              </i>
                            </template> -->
                          </td>
                        </template>
                        <template v-else-if="ticket.game == '3D' || ticket.game == '3D-STL-ILOILO-CITY'">
                          <td> {{item.num1}} - {{item.num2}} - {{item.num3}}

                             <template v-if="item.soldnum.length > 0">
                              <br />
                              <i class="text-warning">Sold Out: 
                                <span v-for="(sold, c) in item.soldnum" :key="c" >
                                  {{sold}}
                                </span>,
                              </i>
                            </template>
                          </td>
                        </template>
                        <template v-else-if="ticket.game == '4D'">
                          <td> {{item.num1}} - {{item.num2}} - {{item.num3}} - {{item.num4}}
                              <!-- <template v-if="item.soldnum.length > 0">
                              <br />
                              <i class="text-warning">Sold Out: 
                                <span v-for="(sold, c) in item.soldnum" :key="c" >
                                  {{sold}}
                                </span>,
                              </i>
                            </template> -->
                          </td>
                        </template>
                          <td class="text-center">{{item.line_type}}</td>
                          <td class="text-right">
                               <span class="text-success">{{$money(item.total_amount)}}</span> <v-chip x-small color="success">Available</v-chip>
                               
                                <template v-if="$IsNum(item.line_amount)>$IsNum(item.total_amount)">
                                    <br />
                                    <span class="text-error"> {{$money(item.line_amount - item.total_amount)}}</span> <v-chip color="error"  x-small>Sold Out</v-chip>
                                </template>
                          </td>
                      </tr>
                  </table>
                   <v-row no-gutters class="text-primary mt-5">
                     <!-- <span style="width: 160px;">
                     <v-text-field
                      dense
                      outlined
                      v-model="drawnum"
                      label="No. of draws"
                      />
                     </span> -->
                    <v-spacer/>
                    <div class="mt-2 text-h6">TOTAL:  {{$money(TOTAL)}}</div>
                  </v-row>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning"  @click="emitToParent('cancel')">CANCEL</v-btn>
            <v-spacer></v-spacer> <v-spacer></v-spacer>
            <v-btn   color="secondary" v-if="TOTAL>0" @click="buyTicket()" :loading="loading">Buy Now</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
      loading: false,
      Templatedrawnum: 1
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    lines: {
        type: Array,
         default: ()=> { return [] }
    },
    ticket: {
        type: Object,
        default: ()=> { return {} }
    }
  },
  watch:{
    show(v){
      if(!v) {
       this.drawnum=1
       this.loading=false
      }
    }
  },
  computed: {
    TOTAL() {
           return (this.lines.reduce((res,item)=>res += this.$IsNum(item.total_amount), 0)) 
      }
  },
  methods: {
     ...mapMutations(["setBettorName"]),
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
        this.ticket = {}
        this.lines = []
    },
    buyTicket(){
      this.loading = true
      if(!this.drawnum ) {
        this.drawnum = 1
      }
      this.ticket.machine_id = window.location.hostname
        this.ticket.Lines = this.lines
        this.ticket.drawnum = parseInt(this.drawnum)
        this.ticket.amount =this.TOTAL.toString()
        this.ticket.code = this.$store.state.bettor_name
        console.log(this.ticket)
         this.$http.post("ticket/new", this.ticket).then(response => {
               this.loading = false
               this.setBettorName("")
                this.$emit('DialogEvent', response.data)
               }).catch(e => {
                console.log(e.data)
            })
    }
  }
}
</script>
