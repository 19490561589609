<template>

  <v-dialog
  tile
    v-model="show"
    persistent
    fullscreen>
    <v-card tile>
      <v-toolbar dense flat color="secondary" dark>
        <v-icon  left color="info">mdi-account-question</v-icon> How to play?
        <v-spacer/>
           <v-btn x-small fab rounded  @click="emitToParent('ok')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
        <v-card-text>

          Please instruction here
          <!-- <table class="va-table print_table3">
              <tr>
                <th valign="center">
                    EZ2/2D
                </th>
                <td  valign="top">
                   Pumili ng dalawang numero from 1 to 31
                   <br/>
                   <br/>
                   Pwede kang pumili ng straight(STD) or Rambol(RBL)
                </td>
                <td  valign="top">
                    Minimum Bet 10
                   <br/>
                   STD win 4,500
                   <br/>
                   RBL win 2,250
                </td>
              </tr>
              <tr>
                <th valign="center">
                    3D
                </th>
                <td  valign="top">
                   Pumili ng tatlo numero from 0 to 9
                   <br/>
                   <br/>
                   Pwede kang pumili ng straight(STD) or Rambol(RBL)
                </td>
                <td  valign="top">
                    Minimum Bet 10
                   <br/>
                   STD win 5,000
                </td>
              </tr> -->
              <!-- <tr>
                <th valign="center">
                  4D
                </th>
                <td  valign="top">
                  Pumili ng apat numero from 0 to 9
                   <br/>
                   <br/>
                   Pwede kang pumili ng straight(STD) or Rambol(RBL)
                </td>
                <td  valign="top">
                    Minimum Bet 10
                   <br/>
                   STD win 10,000
                </td>
              </tr> -->
              <!-- <tr>
                <th valign="center">
                  STL-ILOILO-CITY
                </th>
                <td  valign="top">
                   Pumili ng dalawang numero from 1 to 40
                   <br/>
                   <br/>
                   Pwede kang pumili ng straight(STD) or Rambol(RBL)
                </td>
                <td  valign="top">
                    Minimum Bet 10
                   <br/>
                   STD win 9,000
                </td>
              </tr>
              <tr>
                <th valign="center">
                  STL-ILOILO-PROVINCE
                </th>
                <td  valign="top">
                   Pumili ng dalawang numero from 1 to 40
                   <br/>
                   <br/>
                   Pwede kang pumili ng straight(STD) or Rambol(RBL)
                </td>
                <td  valign="top">
                    Minimum Bet 10
                   <br/>
                   STD win 9,000
                </td>
              </tr>
              <tr>
                <th valign="center">
                  PERYHAN ANTIQUE
                </th>
                <td  valign="top">
                   Pumili ng dalawang numero from 1 to 40
                   <br/>
                   <br/>
                   Pwede kang pumili ng straight(STD) or Rambol(RBL)
                </td>
                <td  valign="top">
                    Minimum Bet 10
                   <br/>
                   STD win 9,000
                </td>
              </tr>


          </table> -->
        
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
