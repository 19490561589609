<template>
     <v-card :class="color" dark>
              <v-card-text style="height: 110px;" class="text-center">
                <span class="text-caption">Event Date: {{$moment(data.event_date).format("MMM, DD, YYYY")}}</span>
                  <div class="text-gold text-shadow text-h6">
                      {{data.name}}
                   </div>
                   <span class="text-caption">{{data.venue}}</span>
                   <div class="text-caption font-italic text-warning" v-if="data.status=='Closed'">Bet Status: {{data.status}}</div>
                   
                </v-card-text>
                <v-card-actions>
                  <v-btn color="tertiary" class="btn-grad-primary"  dark @click="$router.push('/betting/'+data.id)" block> View Event</v-btn>
                </v-card-actions>
            </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: ()=>{}
    },
    sup: {
      type: String,
      default: ''
    }
  },
  computed:{
    NUM(){
        var n = this.data.combination.split(' - ')
        return n
    }
  },
  methods: {

  }

}
</script>