<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen
    >
    <v-card>
       <v-toolbar  dark color="secondary">
            
              <v-icon left @click="emitToParent('cancel')" v-if="ShowClear">mdi-arrow-left</v-icon> You are now playing {{GAMENAME(gamename)}}
            </v-toolbar>
       <v-card-text class="text-center">
         <div class="pt-5">
                  <v-text-field
                        v-model="bettor"
                        label="Bettor's Name"
                        required
                        @change="set_bettor()"
                        outlined
                          hide-details
                        dense
                      ></v-text-field>
         </div>
       </v-card-text>
      
       <template v-if="valid_bettor">
            <v-toolbar  dark color="primary">
              <template v-if="gamename == '3D' || gamename == '3D-STL-ILOILO-CITY'" >
                <v-spacer/> <div class="text-h4"> {{num1}} - {{num2}} - {{num3}}</div> <v-spacer/> 
              </template>
              <template v-else-if="gamename == '4D'" >
                <v-spacer/> <div class="text-h4"> {{num1}} - {{num2}} - {{num3}} - {{num4}}</div> <v-spacer/> 
              </template>
              <template v-else>
                  <v-spacer/> <div class="text-h4"> {{num1}} - {{num2}}</div> <v-spacer/> 
              </template>
             
               <v-btn  x-small fab @click="clear()"  v-if="ShowClear"><v-icon >mdi-close</v-icon></v-btn>
            </v-toolbar>
     
        <v-card-text class="text-center mt-4">
             <template v-if="gamename == '2D' || gamename == 'LASTTWO'">
                 <v-btn fab  color="white" small class="lg-font ma-1" v-for="num in nums" :key="num"  @click="setNum(num)" >{{num}}</v-btn>
                 <v-btn fab  color="warning" small class="lg-font ma-1"  @click="genComb()" >LP</v-btn>
             </template>
             <template v-else-if="gamename == '3D'">
                 <v-btn fab  color="white"  class="lg-font ma-1" v-for="num in nums" :key="num"  @click="setNum(num-1)" >{{num-1}}</v-btn>
                 <v-btn fab  color="warning"  class="lg-font ma-1"  @click="genComb()" >LP</v-btn>
             </template>
            <template v-else>
                 <v-btn fab  color="white" small class="lg-font ma-1" v-for="num in nums" :key="num" @click="setNum(num)" >{{num}}</v-btn>
                 <v-btn fab  color="warning" small class="lg-font ma-1"  @click="genComb()" >LP</v-btn>
            </template>
        </v-card-text>
        <v-row class="ma-2" dense>
            <!-- <v-col cols="6">
                 <v-btn block large color="warning"  @click="emitToParent('cancel')">CANCEL</v-btn>
            </v-col>
            <v-col cols="6">
                   <v-btn  block large color="secondary"    @click="genComb()">LUCKY PICK</v-btn>
            </v-col> -->
            <template>
                <v-col
                cols="6"
                sm="12"
                >
                    <v-btn  block large color="primary"    @click="bet_type='RBL', validateNums()">RAMBOL</v-btn>
                </v-col>
                <v-col
                cols="6"
                sm="12"
                >
                    <v-btn   block large color="secondary"    @click="bet_type='STD', validateNums()">straight</v-btn>
                </v-col>
            </template>
            <!-- <template v-else>
               <v-col
                cols="12"
                >
                    <v-btn   block large color="success"    @click="bet_type='STD', validateNums()">Procced</v-btn>
                </v-col>
            </template> -->
            
           
        </v-row>
       </template>
       
    </v-card>
    <va-amount-form :show="amountDialog" :numarr="ARR_NUM_DISPLAY" :linetype="bet_type" @DialogEvent="amountEvent"/>
  </v-dialog>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
      num1: '_',
      num2: '_',
      num3: '_',
      num4: '_',
      amountDialog: false,
      line: {},
      bet_type:'STD', 
      bettor: '',
      NoRepetetion:["2D-STL-ILOILO-CITY","3D-STL-ILOILO-CITY","STL-SILAY","STL-ILOILO-PROVINCE"]
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    nums: {
       type: Number,
        default: 9
    },
    gamename: {
       type: String,
        default: '2D'
    }
  },
  watch:{
    show(v){
      if(v) {
        this.clear()
        this.bettor = this.$store.state.bettor_name
      }
    }
  },
  computed: {
    ShowClear() {
      if(this.num1 || this.num2) {
        return true
      } 
      return false
    },
     ARR_NUM_DISPLAY() {
      if(this.gamename == "3D" || this.gamename == "3D-STL-ILOILO-CITY") {
           return [this.num1,this.num2, this.num3]
      } else if(this.gamename == "4D") {
           return [this.num1,this.num2, this.num3, this.num4]
      } else {
        return [this.num1,this.num2]
      }
    },
    valid_bettor(){
      if(this.bettor.length> 2) {
        return true
      }
      return true
    }
  },
  methods: {
      ...mapMutations(['setBettorName']),
    set_bettor(){
      this.setBettorName(this.bettor)
    },
    isNoRepet(gamename) {
      var arr = this.NoRepetetion
      for (let i = 0; i < arr.length; i++) {
          if(gamename == arr[i]) {
            return true
          }
      }
       return false
    },
    genComb() {
        if(this.gamename == "3D" || this.gamename == "3D-STL-ILOILO-CITY") {
       this.num1 = this.getRand(1,9)
        this.num2 =this.getRand(1,9)
        this.num3 =this.getRand(1,9)
      
      } else if(this.gamename == "4D") {
        this.num1 = this.getRand(1,9)
        this.num2 =this.getRand(1,9)
        this.num3 =this.getRand(1,9)
        this.num4 =this.getRand(1,9)
      } 
      else {
            this.num1 = this.getRand(1,31)
            this.num2 =this.getRand(1,31)
      
      }
      
    },
    getRand(min, max){
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    amountEvent(e){
      this.amountDialog = false
      if(e != 'close') {
        this.returnLine(e)
      }
    },
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
      this.num1 = '_'
      this.num2 = '_'
      this.num3 = '_'
      this.num4 = '_'
        this.line = {}
        //this.bettor = ''
    },
    returnLine(param) {
      this.line.line_amount = param.amount
      this.line.total_amount =  this.line.line_amount
      this.line.line_type = this.bet_type
      this.line.num1 = this.num1
      this.line.num2 = this.num2
      if(this.gamename == "3D" || this.gamename == "PICK3") {
        this.line.num3 = this.num3
      }
      if(this.gamename == "4D") {
        this.line.num3 = this.num3
         this.line.num4 = this.num4
      }
      if(this.$IsNum(param.amount)>0){
         this.emitToParent({line:this.line, bettor:this.bettor})
      }
     
    },
    hasDuplicate() {
      var arry = this.ARR_NUM_DISPLAY
      for (let i = 0; i < arry.length; i++) {
          for (let c = 0; c < arry.length; c++) {
            if(i != c) {
              if(arry[i] == arry[c]) {
                  return true
              }
            }
          }
        }
        return false
    },
    validateNums(){
      console.log(this.isNoRepet(this.gamename), this.hasDuplicate())
      if(this.isNoRepet(this.gamename)) {
        if(this.hasDuplicate()) {
          this.VA_ALERT('error', "Duplicate number found!")
          return
        }
      }
       if(this.gamename == "3D") {
        if(this.num1 !="_" && this.num2 !="_" && this.num3 !="_") {
          this.amountDialog = true
          } else {
              this.VA_ALERT('error', "Complete number combination!")
          }
       } else if(this.gamename == "4D") {
        if(this.num1 !="_" && this.num2 !="_" && this.num3 !="_" && this.num4 !="_") {
          this.amountDialog = true
          } else {
              this.VA_ALERT('error', "Complete number combination!")
          }
       } else {
         if(this.num1 !="_" && this.num2 !="_") {
          this.amountDialog = true
          } else {
              this.VA_ALERT('error', "Complete number combination!")
          }
       }
    },
    setNum(val) {
      if(this.num1 == '_') {
          this.num1 = val
      } else if(this.num2 == '_') {
         this.num2 = val
      } 
       else if(this.num3 == '_') {
         this.num3 = val
      }  else if(this.num4 == '_') {
         this.num4 = val
      } 
    }
  }
}
</script>
