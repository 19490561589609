import Vue from 'vue'

Vue.prototype.$money = function (amount) {
  if (!amount) { amount = 0 }
  if (isNaN(amount)) {
    amount = 0
  }
  amount = parseFloat(amount).toFixed(2)
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
Vue.prototype.$date_format = "YY-MMM-DD hh:mm A"
Vue.prototype.$IsNum = function (input) {
  if (isNaN(parseFloat(input)) || input == null) {
    return 0
  }
  input = parseFloat(input).toFixed(2)
  return parseFloat(input)
}
Vue.prototype.$NumPad = function (input, pad) {
  if (isNaN(parseFloat(input)) || input == null) {
    return 0
  }
  input = parseFloat(input).toFixed(pad)
  return parseFloat(input)
}
Vue.prototype.$IsEven = function (number) {
  return number % 2 === 0;
}
Vue.prototype.$printThis = function () {
    window.print()
}
Vue.prototype.$groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

  Vue.prototype.$phoneNum = function (inputtxt) {
    var phoneno = "09";
    if(inputtxt.length != 11) {
      return false
    }
    if((inputtxt.match(phoneno))) 
    {
        return true;
    }
  else
    {
    return false;
    }
}