<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="380">
    <v-card color="secondary" dark>
        <v-card-text class="pa-4 subtitle-1">
          <v-form autocomplete="off">
           <v-text-field
            v-model="text"
            class="lg-font"
            style="font-size: 20px; text-align: center;"
            label="Enter amount"
            hide-details
             type="number"
            outlined
            @focus="text=''"
            large
           />
          </v-form>
        
              <!-- <div class="tex-center mt-3">
                   <v-text-field 810120
                      v-model="drawnum"
                      type="number"
                      class="lg-font"
                      style="font-size: 20px; text-align: center;"
                      label="No. of draws"
                      hide-details
                      outlined
                      @focus="drawnum=''"
                      dense
                    />
              </div> -->
            <div class="tex-center mt-3">
              <v-btn tile small class="ma-1"  @click="text=item.toString(), validateAmount()" v-for="(item,index) in amounts" :key="index">{{item}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small text rounded  @click="text ='close', emitToParent()">close</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  rounded  @click="validateAmount()"> <v-icon left >mdi-plus</v-icon> ADD</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        drawnum: 1,
        amounts: [5,10,15, 20,25, 30,40,50,70,80,100]
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    linetype: {
       type: String,
        default: ""
    },
    numarr: {
        type: Array,
        default: ()=>[]
    } 
  },
  watch:{
      show(val) {
          if(!val){this.text=''}
      }
  },
  methods: {
    validateAmount(){
      if(this.$IsNum(this.text.trim()) < 5) {
        this.VA_ALERT('error', "Minimum bet is 5!")
        return
      }
      if(this.$IsNum(this.text.trim())) {
        if("RBL" == this.linetype) {
          var numcount = this.NUMCOMB(this.numarr, this.numarr.length)
          if(this.$IsNum(this.text.trim()) % numcount) {
            this.VA_ALERT('error', "Rambol: Amount must be divisible by " +numcount+ "!")
            return
          }
        }
          if(this.$IsNum(this.text.trim()) < 1) {
            this.VA_ALERT('error', "Bet limit is 1!")
            return
          }

        if(this.$IsNum(this.text.trim()) < 1000) {
           this.emitToParent()
        } else {
          this.VA_ALERT('error', "Entered amount reached the limit!")
        }
      } else {
        this.VA_ALERT('error', "Enter Amount!")
      }
    },
    emitToParent () {
   
      var param = {}
      param.amount = this.text.trim()
     
      this.$emit('DialogEvent', param)
    }
  }
}
</script>
