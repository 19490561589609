<template>
  <v-card :class="color" dark>
           <v-card-text style="height: 110px;" class="text-center">
             <span class="text-caption">{{GAMENAME(data.game)}}</span>
               <div class="text-gold text-shadow">
                   <template v-if="data.game == '3D'">
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[0]}}</span></v-btn>
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[1]}}</span></v-btn> 
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[2]}}</span></v-btn> 
                 </template>
                 <template v-else-if="data.game == '4D'">
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[0]}}</span></v-btn>
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[1]}}</span></v-btn> 
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[2]}}</span></v-btn> 
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[3]}}</span></v-btn> 
                 </template>
                 <template v-else>
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[0]}}</span></v-btn>
                   <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">{{NUM[1]}}</span></v-btn> 
                 </template>
                </div>
                <span class="text-caption">{{data.time}} RESULT</span>
                
             </v-card-text>
             <v-card-actions>
               <v-btn color="tertiary" class="btn-grad-primary" dark @click="$router.push(GameLink(data.game)).catch(err => {})" block> PLAY</v-btn>
             </v-card-actions>
         </v-card>
</template>

<script>
export default {
props: {
 color: {
   type: String,
   default: "secondary"
 },
 icon: {
   type: String,
   default: null
 },
 label: {
   type: String,
   default: ''
 },
 data: {
   type: Object,
   default: ()=>{}
 },
 sup: {
   type: String,
   default: ''
 }
},
computed:{
 NUM(){
     var n = this.data.combination.split(' - ')
     return n
 }
},
methods: {

 GameLink(game) {
   var link = "../play-2d"
   switch(game) {
     case "2D":
       link = "../play-2d"
       break
     case "3D":
       link = "../play-3d"
       break
     case "STL-SILAY":
       link = "../play-stl-silay"
       break
       case "4D":
       link = "../play-4D"
       break
     case "STL-ILOILO-PROVINCE":
       link = "/play-stl-iloilo-province"
       break
     case "2D-STL-ILOILO-CITY":
       link = "/play-2d-stl-iloilo-city"
       break
       case "3D-STL-ILOILO-CITY":
       link = "/play-3d-stl-iloilo-city"
       break
     default:
       link = "/play-2d"
   }
   return link
 }
}

}
</script>