<template>

  <v-dialog
  tile
    v-model="show"
    persistent
    fullscreen>
    <v-card tile>
      <v-toolbar dense flat color="secondary" dark>
        <v-icon  left color="info">mdi-information</v-icon>GUIDELINES/DESCRIPTION
        <v-spacer/>
           <v-btn x-small fab rounded  @click="emitToParent('ok')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
        <v-card-text class="pa-4">
          <p> {{ data }}</p>
       
        
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    data: {
        type: String,
        default: ""
    },
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
