import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loade
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#042632",//"#FBAD16",//'#AC1F62',
            secondary:  '#042632',
            tertiary: '#4CDA63',
            accent: '#AC1F62',
            error: '#f55a4e',
            info: '#00d3ee',
            gold:"#E38011",
            success: "#68BE9F",//#5cb860',
            warning: '#ffa21a'
          },
        },
      },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
});
